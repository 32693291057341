import { ReportData } from 'legacy/components/GroupReports';

import React, { useCallback, useState } from 'react';

import { ChevronSmall } from 'components/svg/ChevronSmall';

import { isMediumScreenSize, Fonts, colors } from 'styles/theme';
import { Bumper } from 'styles/shared';
import { LargeNumberText } from 'legacy/styles/groupReports';

import {
    Heading,
    ItalicText,
    MainWrapper,
    ReportTextResponsesWrapper,
    StatListWrapper,
    StatWrapper,
    ChevronDropdownCaret,
    ChevronDropdownWrapper,
} from 'legacy/styles/reportDropdown';

interface ReportDropdownProps {
    report: ReportData;
}

export const ReportDropdown = ({ report }: ReportDropdownProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const reportDate = new Date(report.start_date);

    const onToggleExpandDropdown = useCallback(() => {
        if (isExpanded) {
            setIsExpanded(false);
        } else {
            setIsExpanded(true);
        }
    }, [isExpanded]);

    return (
        <MainWrapper>
            <Heading onClick={onToggleExpandDropdown}>
                {isMediumScreenSize ? (
                    <Fonts.Heading6 $color={colors.mountainAsh}>
                        {reportDate.toLocaleString('default', {
                            timeZone: 'UTC',
                            month: 'long',
                            year: 'numeric',
                        })}
                    </Fonts.Heading6>
                ) : (
                    <Fonts.Heading5 $color={colors.mountainAsh}>
                        {reportDate.toLocaleString('default', {
                            timeZone: 'UTC',
                            month: 'long',
                            year: 'numeric',
                        })}
                    </Fonts.Heading5>
                )}
                <ChevronDropdownWrapper $isDropdownOpen={isExpanded}>
                    <ChevronDropdownCaret>
                        <ChevronSmall />
                    </ChevronDropdownCaret>
                </ChevronDropdownWrapper>
            </Heading>

            {isExpanded ? (
                <>
                    <Bumper />
                    <Bumper />
                    <Bumper />
                    <StatListWrapper>
                        <StatWrapper>
                            <Fonts.Medium
                                $isOpenSansSemiBold
                                $color={colors.mountainAsh}
                            >{`Session score average:`}</Fonts.Medium>
                            <LargeNumberText $color={colors.darkJungle}>
                                {report.current_session_score_avg?.toFixed(1)}
                            </LargeNumberText>
                            <Fonts.XSmall>
                                {`When asked on a on a scale from Strongly Disagree ("1") to Strongly Agree ("5"), whether this session was a great use of time`}
                            </Fonts.XSmall>
                        </StatWrapper>
                        <StatWrapper>
                            <Fonts.Medium
                                $isOpenSansSemiBold
                                $color={colors.mountainAsh}
                            >{`Attendance average:`}</Fonts.Medium>
                            <LargeNumberText>
                                {`${(
                                    (report.attendance_avg ?? 0) * 100
                                ).toFixed(0)}%`}
                            </LargeNumberText>
                            <Fonts.XSmall>
                                {`Attendance per session ranges from 50% in the bottom 25th percentile to 85% in the 75th percentile. Fluctuations in attendance are expected and monitored daily by our team. We will proactively reach out to you if we need support in increasing attendance for your groups.`}
                            </Fonts.XSmall>
                        </StatWrapper>
                        <StatWrapper>
                            <Fonts.Medium
                                $isOpenSansSemiBold
                                $color={colors.mountainAsh}
                            >{`Members engaged:`}</Fonts.Medium>
                            <LargeNumberText>
                                {report.present_members}
                            </LargeNumberText>
                            <Fonts.XSmall>
                                {`This number represents the total members who attended a session in this month`}
                            </Fonts.XSmall>
                        </StatWrapper>
                    </StatListWrapper>
                    <Bumper />
                    <Bumper />
                    <Bumper />

                    <ReportTextResponsesWrapper>
                        <Fonts.Medium
                            $isOpenSansSemiBold
                            $color={colors.mountainAsh}
                        >{`Key Takeaways:`}</Fonts.Medium>
                        {report.takeaways?.map((takeaway) => (
                            <>
                                <Bumper />
                                <ItalicText key={takeaway}>
                                    {takeaway}
                                </ItalicText>
                            </>
                        ))}
                        <Bumper />
                        {report.closing_words == null ||
                        report.closing_words === '' ? null : (
                            <>
                                <Bumper />
                                <Bumper />
                                <Fonts.Medium
                                    $isOpenSansSemiBold
                                    $color={colors.mountainAsh}
                                >{`Closing words:`}</Fonts.Medium>
                                <br />
                                <Fonts.XSmall>{`The sentiment that members shared at the end of the session:`}</Fonts.XSmall>
                                <Bumper />
                                <Fonts.Medium>
                                    {report.closing_words}
                                </Fonts.Medium>
                                <Bumper />
                            </>
                        )}
                    </ReportTextResponsesWrapper>
                </>
            ) : null}
        </MainWrapper>
    );
};

export default ReportDropdown;
